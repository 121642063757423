/* src/inc/adminmenu.js */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Head from "./header";

const AdminMenu = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Head />
      <h2>관리자메뉴 </h2>
      <div className="form">
        <p>
          <br />
          <br />
          <input
            className="mbtn"
            type="submit"
            value="사용자등록"
            onClick={() => {
              navigate("/regUser");
            }}
          />
        </p>
      </div>
    </div>
  );
};

export default AdminMenu;
