import React, { Component } from "react";
import Head from "./header";
import axios from "axios";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      userNo: "",
      userPassword: "",

      userName: "",
      userPhone: "",
      userEMail: "",
      userTaxinfo: "",
      userVehicleNo: "",
      userApproved: 0,

      login: false,
    };
  }

  _isLogin = async (e) => {
    const userNo = this.state.userNo.trim();
    const userPassword = this.state.userPassword.trim();
    if (userNo === "") {
      return alert("아이디를 입력해주세요.");
    } else if (userPassword === "") {
      return alert("비밀번호를 입력해주세요.");
    }

    const obj = { userNo: userNo, userPassword: userPassword };

    const res = await axios("/send/driver", {
      method: "POST",
      data: obj,
      headers: new Headers(),
    });
    if (res.data) {
      console.log(res.data.msg);
      if (res.data.suc) {
        sessionStorage.setItem("login", true);
        this.setState({ login: true });
        alert("로그인 되었습니다.");
        return window.location.replace("/menu");
      } else {
        return alert("아이디 및 비밀번호가 일치 하지 않습니다.");
      }
    }
  };

  _changeID = function () {
    const userNo_v = document.getElementsByName("userNo")[0].value;

    this.setState({
      userNo: userNo_v,
    });
  };
  _changePW = function () {
    const userPassword_v = document.getElementsByName("userPassword")[0].value;

    this.setState({
      userPassword: userPassword_v,
    });
  };

  render() {
    return (
      <div>
        <Head />
        <h2>로그인</h2>

        <div className="form">
          <p>
            <input
              className="login"
              type="text"
              name="userNo"
              placeholder="차량번호"
              onChange={() => this._changeID()}
            />
          </p>
          <p>
            <input
              className="login"
              type="password"
              name="userPassword"
              placeholder="비밀번호"
              onChange={() => this._changePW()}
            />
          </p>

          <p>
            <input
              className="btn"
              type="submit"
              value="로그인"
              onClick={this._isLogin}
            />
          </p>
        </div>
      </div>
    );
  }
}

export default Login;
