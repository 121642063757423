import React, { Component } from "react";
import "./App.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Switch,
  useNavigate,
} from "react-router-dom";

import {
  Home,
  Test,
  Head,
  Login,
  Menu,
  RegVoucher,
  LstVoucher,
  RegUser,
  AdminMenu,
} from "./inc";

function HanaComponent() {
  const navigate = useNavigate();
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" Component={Home} />
            <Route path="/test" Component={Test} />
            <Route path="/Login" Component={Login} />
            <Route path="/Menu" Component={Menu} />
            <Route path="/adminmenu" Component={AdminMenu} />
            <Route path="/lstVoucher" Component={LstVoucher} />

            <Route path="/regVoucher" Component={RegVoucher} />
            <Route path="/regUser" Component={RegUser} />
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
