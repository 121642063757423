import React, { Component } from "react";
import { useNavigate } from "react-router-dom";
import Head from "./header";

const Home = () => {
  const navigate = useNavigate();

  return (
    <div className="Home">
      <Head />
      <img src={`/assets/logo192.png`} width="300px" />
      <h2>H Limousine</h2>
      <br />
      <h3>If you are not a hana limousine employee,</h3>
      <h3>please ask the administrator to register. </h3>
      <h3> Connect: hanalimo@gmail.com </h3>
      <br />
      <img
        src={`/assets/Account-Login-Button.png`}
        width="300px"
        onClick={() => {
          sessionStorage.adminlogin
            ? navigate("/adminmenu")
            : navigate("/login");
        }}
      />
    </div>
  );
};

export default Home;
