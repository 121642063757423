/* src/inc/regVouchjer.js */

import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Head from "./header";

const RegVoucher = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Head />
      <h2>HANA Limousune</h2>
      <h6>Web: www.hanaalt.com,EML: hanalimo@gmail.com</h6>

      <div className="form">
        <p>
          {" "}
          Payment Type :
          <select>
            <option key="Voucher" value="Voucher">
              Voucher
            </option>
            <option key="Receipt" value="Receipt">
              Receipt
            </option>
            <option key="Card" value="Card">
              Card
            </option>
            <option key="Cash" value="Cash">
              Cash
            </option>
          </select>
        </p>
        <table>
          <caption>*항목은 반드시 입력 하세요</caption>
          <thead>
            <tr>
              <th scope="col" colspan="2" align="center">
                승객정보
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {" "}
              <td>Date</td>
              <td>
                <input type="text" name="voDate" placeholder="날짜" />
              </td>
            </tr>
            <tr>
              {" "}
              <td>Compay code</td>
              <td>
                <input type="text" name="voDate" placeholder="회사코드" />
              </td>
            </tr>
            <tr>
              {" "}
              <td colspan="2">
                <input type="text" name="voDate" placeholder="ABC" />
              </td>
            </tr>
            <tr>
              {" "}
              <td>Account No</td>
              <td>
                <input type="text" name="voDate" placeholder="Account No" />
              </td>
            </tr>
            <tr>
              {" "}
              <td>Passenger</td>
              <td>
                <input type="text" name="voDate" placeholder="Passenger" />
              </td>
            </tr>
            <tr>
              {" "}
              <td>Order No</td>
              <td>
                <input type="text" name="voDate" placeholder="Order No" />
              </td>
            </tr>
            <tr>
              {" "}
              <td>Vehicle No</td>
              <td>
                <input type="text" name="voDate" placeholder="Vehicle No" />
              </td>
            </tr>
            <tr>
              {" "}
              <td>Schedule Time</td>
              <td>
                <input type="text" name="voDate" placeholder="Schedule Time" />
              </td>
            </tr>
            <tr>
              {" "}
              <td>Pickup Time</td>
              <td>
                <input type="text" name="voDate" placeholder="Pickup Time" />
              </td>
            </tr>
            <tr>
              {" "}
              <td>From</td>
              <td>
                <input type="text" name="voDate" placeholder="From" />
              </td>
            </tr>
            <tr>
              {" "}
              <td>To</td>
              <td>
                <input type="text" name="voDate" placeholder="To" />
              </td>
            </tr>
            <tr>
              {" "}
              <td>Remark</td>
              <td>
                <input type="text" name="voDate" placeholder="Remark" />
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th scope="col" colspan="2" align="center">
                요금정보
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {" "}
              <td>Fare</td>
              <td>
                <input type="text" name="voDate" placeholder="Fare" />
              </td>
            </tr>
            <tr>
              {" "}
              <td>W.T.</td>
              <td>
                <input type="text" name="voDate" placeholder="W.T." />
              </td>
            </tr>
            <tr>
              {" "}
              <td>Toll</td>
              <td>
                <input type="text" name="voDate" placeholder="Toll" />
              </td>
            </tr>
            <tr>
              {" "}
              <td>Parking</td>
              <td>
                <input type="text" name="voDate" placeholder="Parking" />
              </td>
            </tr>
            <tr>
              {" "}
              <td>Spec-Chg</td>
              <td>
                <input type="text" name="voDate" placeholder="Spec-Chg" />
              </td>
            </tr>
            <tr>
              {" "}
              <td>Gratuity</td>
              <td>
                <input type="text" name="voDate" placeholder="Gratuity" />
              </td>
            </tr>
            <tr>
              {" "}
              <td>Total</td>
              <td>
                <input type="text" name="voDate" placeholder="Total" />
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th scope="col" colspan="2" align="center"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {" "}
              <td>
                <input
                  className="btn"
                  type="submit"
                  value="취소"
                  onClick={() => {
                    navigate("/menu");
                  }}
                />
              </td>
              <td>
                <input
                  className="btn"
                  type="submit"
                  value="저장"
                  onClick={() => {
                    navigate("/menu");
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RegVoucher;
