/* src/inc/menu.js */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Head from "./header";

const Menu = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Head />
      <h2>금일 운행 건수 </h2>
      <div className="form">
        <p>
          <input
            className="mbtn"
            type="submit"
            value="바우처등록"
            onClick={() => {
              navigate("/regVoucher");
            }}
          />
          <br />
          <br />
          <br />
          <input
            className="mbtn"
            type="submit"
            value="바우처조회"
            onClick={() => {
              navigate("/lstVoucher");
            }}
          />
        </p>
        <p>
          <br />
          <br />
          <input
            className="mbtn"
            type="submit"
            value="개인정보 수정"
            onClick={() => {
              navigate("/regUser");
            }}
          />
        </p>
      </div>
    </div>
  );
};

export default Menu;
