/* src/inc/header.js */

import React, { Component } from "react";
import { Routes, Route, Link } from "react-router-dom";

import Modal from "react-awesome-modal";

import axios from "axios";

import "../App.css";

class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      id: "",
      password: "",
      adminlogin: false,
      login: false,
    };
  }

  componentDidMount() {
    if (sessionStorage.adminlogin) {
      this.setState({ adminlogin: true });
    }
  }

  _openModal = function () {
    this.setState({
      visible: true,
    });
  };

  _closeModal = function () {
    this.setState({
      visible: false,
    });
  };
  _changeID = function () {
    const id_v = document.getElementsByName("id")[0].value;

    this.setState({
      id: id_v,
    });
  };
  _changePW = function () {
    const pw_v = document.getElementsByName("password")[0].value;

    this.setState({
      password: pw_v,
    });
  };

  _selectUserData = async (e) => {
    const id = this.state.id.trim();
    const password = this.state.password.trim();

    if (id === "") {
      return alert("아이디를 입력해주세요.");
    } else if (password === "") {
      return alert("비밀번호를 입력해주세요.");
    }

    const obj = { id: id, password: password };

    const res = await axios("/send/pw", {
      method: "POST",
      data: obj,
      headers: new Headers(),
    });

    if (res.data) {
      console.log(res.data.msg);
      if (res.data.suc) {
        sessionStorage.setItem("adminlogin", true);
        this.setState({ adminlogin: true });
        this._closeModal();
        return alert("관리자 로그인 되었습니다.");
      } else {
        return alert("아이디 및 비밀번호가 일치 하지 않습니다.");
      }
    }
  };

  _logout = function () {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      sessionStorage.removeItem("adminlogin");
      this.setState({ adminlogin: false });
    }
  };

  render() {
    return (
      <div class="header_grid">
        <div> </div>
        <div className="acenter">
          <Routes>
            <Route path="/" />
          </Routes>
          <Link className="link_tit" to="/">
            {" "}
            <h4> HANA Limousine Driver's </h4>{" "}
          </Link>
        </div>

        <div className="acenter">
          {" "}
          {this.state.adminlogin ? (
            <h5 className="btn_cursor" onClick={() => this._logout()}>
              {" "}
              관리자 로그아웃{" "}
            </h5>
          ) : (
            <h5 className="btn_cursor" onClick={() => this._openModal()}>
              {" "}
              관리자 로그인{" "}
            </h5>
          )}{" "}
          <Modal
            visible={this.state.visible}
            width="400"
            height="360"
            effect="fadeInDown"
            onClickAway={() => this._closeModal()}
          >
            <div>
              <h4 className="acenter login_tit">Admin</h4>
              <form>
                <div className="login_div">
                  <div className="login_input_div">
                    <h3> Administrator ID </h3>
                    <input
                      type="text"
                      name="id"
                      onChange={() => this._changeID()}
                    />
                  </div>

                  <div
                    className="login_input_div"
                    style={{ marginTop: "40px" }}
                  >
                    <h3> Password </h3>
                    <input
                      type="password"
                      name="password"
                      onChange={() => this._changePW()}
                    />
                  </div>

                  <div className="submit_div">
                    <div>
                      {" "}
                      <input
                        type="button"
                        value="로그인"
                        onClick={() => this._selectUserData()}
                      />{" "}
                      <input
                        type="button"
                        value="취소"
                        onClick={() => this._closeModal()}
                      />{" "}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </Modal>
        </div>
      </div>
    );
  }
}

export default header;
