/* src/inc/test.js */

import React, { Component } from "react";

class test extends Component {
  render() {
    return (
      <div>
        <h3> This is test page </h3>
      </div>
    );
  }
}

export default test;
