/* src/inc/lstVouchjer.js */

import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Head from "./header";

const LstVoucher = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Head />
      <div className="form">
        <table>
          <thead>
            <tr>
              <th width="4%">Id</th>
              <th width="6%">Date</th>
              <th width="10%">Comp.</th>
              <th width="30%">From</th>
              <th width="30%">To</th>
              <th width="20%">Earn</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {" "}
              <td>1</td>
              <td>06/14</td>
              <td>DINA</td>
              <td>강식당</td>
              <td>655 Peachtree Industrial</td>
              <td>50.00</td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th scope="col" colspan="2" align="center"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              {" "}
              <td>
                <input
                  className="btn"
                  type="submit"
                  value="닫기"
                  onClick={() => {
                    navigate("/menu");
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LstVoucher;
